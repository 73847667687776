<template>
  <div class="cpi-opportunity">
    <v-container>
      <AppButton
        prepend-icon="fas fa-chevron-left"
        :disabled="saving"
        dense
        variation="ghost"
        class="pa-0"
        @click="backToList"
      >
        {{ $t('cpiOpportunity.back') }}
      </AppButton>
      <div>
        <h3 class="d-inline mr-2">{{ $t('cpiOpportunity.title') }}</h3>
        <AppButton
          variation="ghost"
          style="min-width: 10rem"
          :loading="saving"
          @click="showResultEditDialog = true"
        >
          {{ $t('cpiOpportunity.editStatus') }}
        </AppButton>
      </div>
      <v-row dense>
        <v-chip
          v-if="cpiOpportunity.result"
          :color="cpiOpportunity.status === 'open' ? 'success' : 'default'"
          class="d-inline ml-1 my-2"
        >
          {{ $t(`cpiOpportunity.results.${cpiOpportunity.result}`) }}
        </v-chip>
      </v-row>

      <v-row dense>
        <v-col>
          <LabeledInput
            :label="$t('cpiOpportunity.form.borrowerName')"
            required
          >
            <v-text-field
              v-model="cpiOpportunity.borrowerName"
              dense
              outlined
              hide-details
            />
          </LabeledInput>
        </v-col>
      </v-row>

      <v-sheet class="elevation-1 pa-6 mt-6 mb-6">
        <h5 class="section-title mb-4">
          {{ $t('cpiOpportunity.overallSectionTitle') }}
        </h5>
        <v-row dense>
          <v-col>
            <LabeledInput :label="$t('cpiOpportunity.form.nextSteps')">
              <v-textarea
                v-model="cpiOpportunity.nextSteps"
                auto-grow
                outlined
                hide-details
              />
            </LabeledInput>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <LabeledInput :label="$t('cpiOpportunity.form.dealLead')">
              <v-text-field
                v-model="cpiOpportunity.dealLead"
                hide-details
                dense
                outlined
              />
            </LabeledInput>
          </v-col>
          <v-col>
            <LabeledInput :label="$t('cpiOpportunity.form.aonConnectId')">
              <v-text-field
                v-model="cpiOpportunity.aonConnectId"
                hide-details
                dense
                outlined
              />
            </LabeledInput>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <LabeledInput :label="$t('cpiOpportunity.form.notes')">
              <v-textarea
                v-model="cpiOpportunity.notes"
                hide-details
                auto-grow
                outlined
              />
            </LabeledInput>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet class="elevation-1 pa-6 mb-6">
        <h5 class="section-title mb-4">
          {{ $t('cpiOpportunity.lenderSectionTitle') }}
        </h5>
        <v-row dense>
          <v-col>
            <LabeledInput :label="$t('cpiOpportunity.form.lender')">
              <v-select
                v-model="cpiOpportunity.lenderName"
                :items="lenderItems"
                placeholder="Select Lender"
                hide-details
                dense
                outlined
              />
            </LabeledInput>
          </v-col>
        </v-row>
        <v-row v-if="cpiOpportunity.lenderName === 'Other'" dense>
          <v-col>
            <LabeledInput :label="$t('cpiOpportunity.form.lenderName')">
              <v-text-field
                v-model="cpiOpportunity.otherLenderName"
                hide-details
                dense
                outlined
              />
            </LabeledInput>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <LabeledInput :label="$t('cpiOpportunity.form.valuationPriority')">
              <v-select
                v-model.number="cpiOpportunity.valuationPriority"
                :items="valuationPriorityItems"
                hide-details
                dense
                outlined
              />
            </LabeledInput>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col cols="2">
            <LabeledInput
              :label="$t('cpiOpportunity.form.exclusivityExpiration')"
            >
              <DatePicker :date.sync="exclusivityExpiration" label="" dense />
            </LabeledInput>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet class="elevation-1 pa-6 mb-6">
        <h5 class="section-title mb-4">
          {{ $t('cpiOpportunity.financialSectionTitle') }}
        </h5>
        <v-row dense class="pb-0">
          <v-col cols="3" class="mr-2">
            <LabeledInput :label="$t('cpiOpportunity.form.targetCloseDate')">
              <DatePicker :date.sync="targetCloseDate" label="" dense />
            </LabeledInput>
          </v-col>
          <v-col cols="2">
            <LabeledInput
              v-if="
                cpiOpportunity.maxLoanAmount && cpiOpportunity.maxLoanAmount > 0
              "
              :label="$t('cpiOpportunity.form.minLoanAmount')"
            >
              <v-text-field
                v-model.number="cpiOpportunity.minLoanAmount"
                class="hide-number-spinner"
                suffix="($M)"
                type="number"
                :error-messages="
                  getError($v.cpiOpportunity.minLoanAmount, 'minLoanAmount')
                "
                dense
                outlined
              />
            </LabeledInput>
            <LabeledInput
              v-else
              :label="$t('cpiOpportunity.form.minLoanAmount')"
            >
              <v-text-field
                value=""
                class="hide-number-spinner"
                suffix="($M)"
                type="number"
                disabled
                hide-details
                dense
                outlined
              />
            </LabeledInput>
          </v-col>
          <v-col cols="2">
            <LabeledInput :label="$t('cpiOpportunity.form.maxLoanAmount')">
              <v-text-field
                v-model.number="cpiOpportunity.maxLoanAmount"
                class="hide-number-spinner"
                suffix="($M)"
                type="number"
                :error-messages="
                  getError($v.cpiOpportunity.maxLoanAmount, 'maxLoanAmount')
                "
                dense
                outlined
              />
            </LabeledInput>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet class="elevation-1 pa-6 mb-6">
        <h5 class="section-title mb-4">
          {{ $t('cpiOpportunity.creditAnalysisSectionTitle') }}
        </h5>
        <v-row dense>
          <v-col>
            <LabeledInput :label="$t('cpiOpportunity.form.creditAnalysis')">
              <v-select
                v-model="cpiOpportunity.creditAnalysis"
                :items="creditAnalysisItems"
                hide-details
                dense
                outlined
              />
            </LabeledInput>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <LabeledInput
              :label="$t('cpiOpportunity.form.creditAnalysisSummary')"
            >
              <v-textarea
                v-model="cpiOpportunity.creditAnalysisSummary"
                auto-grow
                hide-details
                outlined
              />
            </LabeledInput>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet class="elevation-1 pa-6 mb-6">
        <h5 class="section-title mb-4">
          {{ $t('cpiOpportunity.patentAnalysisSectionTitle') }}
        </h5>
        <v-row dense>
          <v-col>
            <LabeledInput :label="$t('cpiOpportunity.form.patentAnalysis')">
              <v-select
                v-model="cpiOpportunity.patentAnalysis"
                :items="patentAnalysisItems"
                hide-details
                dense
                outlined
              />
            </LabeledInput>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col>
            <LabeledInput
              :label="$t('cpiOpportunity.form.patentAnalysisSummary')"
            >
              <v-textarea
                v-model="cpiOpportunity.patentAnalysisSummary"
                auto-grow
                hide-details
                outlined
              />
            </LabeledInput>
          </v-col>
        </v-row>
      </v-sheet>

      <v-sheet class="elevation-1 pa-6 mb-6">
        <h5 class="section-title mb-4">
          {{ $t('cpiOpportunity.taskItemsSectionTitle') }}
        </h5>
        <v-row>
          <v-col>
            <v-card flat>
              <v-row class="mx-1">
                <v-col cols="2" class="subtitle-1 font-weight-bold mt-auto">
                  {{ $t('cpiOpportunity.milestone') }}
                </v-col>
                <v-col cols="4" class="subtitle-1 font-weight-bold mt-auto">
                  {{ $t('cpiOpportunity.responsibility') }}
                </v-col>
                <v-col class="subtitle-1 font-weight-bold mt-auto">
                  <v-checkbox
                    v-model="displayTaskItemStatuses"
                    class="ma-0"
                    :label="$t('cpiOpportunity.taskItemStatusItems.notStarted')"
                    value="Not Started"
                    color="warning"
                    hide-details
                  />
                  <v-checkbox
                    v-model="displayTaskItemStatuses"
                    class="ma-0"
                    :label="$t('cpiOpportunity.taskItemStatusItems.inProgress')"
                    value="In Progress"
                    color="success"
                    hide-details
                  />
                  <v-checkbox
                    v-model="displayTaskItemStatuses"
                    class="ma-0"
                    :label="$t('cpiOpportunity.taskItemStatusItems.done')"
                    value="Done"
                    color="success"
                    hide-details
                  />
                  <v-checkbox
                    v-model="displayTaskItemStatuses"
                    class="ma-0 mb-2"
                    :label="$t('cpiOpportunity.taskItemStatusItems.NA')"
                    value="N/A"
                    color="default"
                    hide-details
                  />
                  {{ $t('cpiOpportunity.status') }}
                </v-col>
                <v-col cols="2" class="subtitle-1 font-weight-bold mt-auto">
                  {{ $t('cpiOpportunity.targetCloseDate') }}
                </v-col>
              </v-row>
            </v-card>
            <div v-for="section in taskSections" :key="section" class="mb-6">
              <v-card tile outlined>
                <v-card-text class="pl-1 py-2">
                  <v-row>
                    <v-col class="my-auto">
                      <h5>{{ $t(`cpiOpportunity.sections.${section}`) }}</h5>
                    </v-col>
                    <v-col cols="auto">
                      <AppButton
                        variation="icon"
                        @click="addTaskItemDialog(section)"
                      >
                        <i class="fas fa-plus" />
                      </AppButton>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <template v-for="item in getSectionTaskItems(section)">
                <v-card :key="item.title" tile outlined>
                  <v-row>
                    <template v-if="item.readonly">
                      <v-col cols="2" class="my-auto">
                        {{ item.title }}
                      </v-col>
                    </template>
                    <template v-else>
                      <v-col cols="2" class="mt-4">
                        <v-text-field v-model="item.title" outlined dense />
                      </v-col>
                    </template>
                    <v-col cols="3" class="mt-4">
                      <v-text-field
                        v-model="item.responsibility"
                        outlined
                        dense
                      />
                    </v-col>
                    <v-col cols="auto" class="mt-2">
                      <v-radio-group
                        v-model="item.status"
                        class="action-item-status"
                        hide-details
                        row
                      >
                        <v-radio
                          :label="
                            $t('cpiOpportunity.taskItemStatusItems.notStarted')
                          "
                          value="Not Started"
                          color="warning"
                        />
                        <v-radio
                          :label="
                            $t('cpiOpportunity.taskItemStatusItems.inProgress')
                          "
                          value="In Progress"
                          color="success"
                        />
                        <v-radio
                          :label="$t('cpiOpportunity.taskItemStatusItems.done')"
                          value="Done"
                          color="success"
                        />
                        <v-radio
                          :label="$t('cpiOpportunity.taskItemStatusItems.NA')"
                          value="N/A"
                          color="default"
                        />
                      </v-radio-group>
                    </v-col>
                    <v-col cols="2" class="mt-4">
                      <DatePicker
                        :date="formatDatePicker(item.targetDate)"
                        label=""
                        dense
                      />
                    </v-col>
                    <!-- <v-col cols="auto" class="mt-4">
                      <AppButton v-if="!item.readonly" variation="icon">
                        <i class="fas fa-trash-alt" />
                      </AppButton>
                    </v-col> -->
                  </v-row>
                </v-card>
              </template>
            </div>
          </v-col>
        </v-row>
      </v-sheet>
    </v-container>
    <v-dialog
      v-model="showResultEditDialog"
      width="400"
      @click:outside="showResultEditDialog = false"
    >
      <v-card>
        <v-card-title>
          {{ $t('cpiOpportunity.editStatus') }}
        </v-card-title>
        <v-card-text>
          <v-radio-group v-model="opportunityResult">
            <template #label>
              {{ $t('cpiOpportunity.opportunityResultTitle') }}
            </template>
            <v-radio
              v-for="openResult in openOpportunityResults"
              :key="openResult"
              :label="$t(`cpiOpportunity.results.${openResult}`)"
              :value="openResult"
            />
            <v-radio
              v-for="closedResult in closedOpportunityResults"
              :key="closedResult"
              :label="$t(`cpiOpportunity.results.${closedResult}`)"
              :value="closedResult"
            />
          </v-radio-group>
        </v-card-text>
        <v-card-actions>
          <AppButton :loading="saving" @click="saveOpportunityResult">
            {{ $t('cpiOpportunity.save') }}
          </AppButton>
          <AppButton
            variation="ghost"
            :loading="saving"
            @click="showResultEditDialog = false"
          >
            {{ $t('cpiOpportunity.cancel') }}
          </AppButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showAddTaskItemDialog"
      @click:outside="showAddTaskItemDialog = false"
    >
      <v-card>
        <v-card-title>
          {{ $t('cpiOpportunity.addTaskItem') }}
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="auto">Milestone</v-col>
              <v-col>
                <v-text-field v-model="customTaskItemName" outlined dense />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <AppButton
            :loading="saving"
            :disabled="!customTaskItemName"
            @click="saveCustomTaskItem"
          >
            {{ $t('cpiOpportunity.save') }}
          </AppButton>
          <AppButton
            variation="ghost"
            :loading="saving"
            @click="showAddTaskItemDialog = false"
          >
            {{ $t('cpiOpportunity.cancel') }}
          </AppButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <AppButton
      style="min-width:10rem;position:fixed;right:5px;bottom:20px"
      :loading="saving"
      :disabled="loading || !cpiOpportunity.borrowerName.trim()"
      @click="updateCpiOpportunity"
    >
      {{ $t('cpiOpportunity.saveChanges') }}
    </AppButton>
  </div>
</template>

<script>
import {
  getCpiOpportunity,
  updateCpiOpportunity,
  createOpportunityTask,
} from '@/api/cpiOpportunities';
import LabeledInput from '@aon/cfs-components/src/components/Forms/LabeledInput';
import DatePicker from '@aon/cfs-components/src/components/Forms/DatePickerTextField';
import { minValue, numeric } from 'vuelidate/lib/validators';
import scrollToFirstError from '@aon/cfs-components/src/mixins/scroll-to-first-error';

export default {
  name: 'CpiOpportunity',
  components: {
    LabeledInput,
    DatePicker,
  },
  mixins: [scrollToFirstError],
  data() {
    return {
      saving: false,
      loading: false,
      cpiOpportunity: {},
      cpiOpportunityTaskItems: {},
      showResultEditDialog: false,
      showAddTaskItemDialog: false,
      selectedSection: null,
      customTaskItemName: null,
      opportunityResult: null,
      selectedLender: null,
      otherLenderName: null,
      displayTaskItemStatuses: ['Not Started', 'In Progress', 'Done', 'N/A'],
      openOpportunityResults: [
        'early',
        'icDiligence',
        'termSheetIssued',
        'termSheetSigned',
      ],
      closedOpportunityResults: [
        'lenderDeclined',
        'borrowerDeclined',
        'loanIssued',
      ],
      lenderItems: [
        {
          text: 'None',
          value: null,
        },
        {
          text: this.$t('cpiOpportunity.lenderItems.aon'),
          value: 'Aon Fund',
        },
        {
          text: this.$t('cpiOpportunity.lenderItems.atlas'),
          value: 'Atlas',
        },
        {
          text: this.$t('cpiOpportunity.lenderItems.bain'),
          value: 'Bain',
        },
        {
          text: this.$t('cpiOpportunity.lenderItems.jefferies'),
          value: 'Jefferies',
        },
        {
          text: this.$t('cpiOpportunity.lenderItems.other'),
          value: 'Other',
        },
      ],
      creditAnalysisItems: [
        {
          text: this.$t('cpiOpportunity.creditAnalysisItems.excellent'),
          value: 'Excellent',
        },
        {
          text: this.$t('cpiOpportunity.creditAnalysisItems.adequate'),
          value: 'Adequate',
        },
        {
          text: this.$t('cpiOpportunity.creditAnalysisItems.poor'),
          value: 'Poor',
        },
      ],
      patentAnalysisItems: [
        {
          text: this.$t('cpiOpportunity.patentAnalysisItems.strong'),
          value: 'Strong',
        },
        {
          text: this.$t('cpiOpportunity.patentAnalysisItems.sellable'),
          value: 'Sellable',
        },
        {
          text: this.$t('cpiOpportunity.patentAnalysisItems.poor'),
          value: 'Poor',
        },
        {
          text: this.$t('cpiOpportunity.patentAnalysisItems.reject'),
          value: 'Reject',
        },
        {
          text: this.$t('cpiOpportunity.patentAnalysisItems.undetermined'),
          value: 'Undetermined',
        },
      ],
      valuationPriorityItems: [
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.noValuation'),
          value: null,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.1'),
          value: 1,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.2'),
          value: 2,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.3'),
          value: 3,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.4'),
          value: 4,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.5'),
          value: 5,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.6'),
          value: 6,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.7'),
          value: 7,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.8'),
          value: 8,
        },
        {
          text: this.$t('cpiOpportunity.valuationPriorityItems.9'),
          value: 9,
        },
      ],
      taskSections: [
        'prepareForDiligence',
        'diligencePhase',
        'alignMarkets',
        'negotiateTerms',
        'execution',
      ],
    };
  },
  validations: {
    cpiOpportunity: {
      minLoanAmount: {
        numeric,
        minValue: minValue(0),
        maxValue: (value, fields) =>
          !!fields.maxLoanAmount && fields.maxLoanAmount > 0
            ? value < fields.maxLoanAmount
            : true,
      },
      maxLoanAmount: {
        numeric,
        minValue: minValue(0),
      },
    },
  },
  computed: {
    targetCloseDate: {
      get() {
        return this.formatDatePicker(this.cpiOpportunity.targetCloseDate);
      },
      set(value) {
        this.cpiOpportunity.targetCloseDate = value;
      },
    },
    exclusivityExpiration: {
      get() {
        return this.formatDatePicker(this.cpiOpportunity.exclusivityExpiration);
      },
      set(value) {
        this.cpiOpportunity.exclusivityExpiration = value;
      },
    },
  },
  async created() {
    this.loading = true;
    try {
      const opportunityResult = await getCpiOpportunity(this.$route.params.id);
      this.cpiOpportunity = opportunityResult.data;
      const taskItems = this.taskSections.reduce((items, cur) => {
        items[cur] = [];
        return items;
      }, this.cpiOpportunityTaskItems);
      this.cpiOpportunity.taskItems.forEach(item =>
        taskItems[item.section].push(item)
      );
      this.cpiOpportunityTaskItems = taskItems;
      this.opportunityResult = this.cpiOpportunity.result;
    } finally {
      this.loading = false;
    }
  },
  methods: {
    getError(model, fieldName) {
      const errors = [];
      switch (fieldName) {
        case 'minLoanAmount':
          !model.minValue && errors.push('Value must be greater than 0');
          !model.maxValue &&
            errors.push('Value must be less than Max Loan Amount');
          break;
        case 'maxLoanAmount':
          !model.minValue && errors.push('Value must be greater than 0');
          break;
      }
      return errors;
    },
    getSectionTaskItems(section) {
      let taskItems = this.cpiOpportunityTaskItems[section] || [];

      if (this.displayTaskItemStatuses.length > 0) {
        taskItems = taskItems.filter(taskItem =>
          this.displayTaskItemStatuses.includes(taskItem.status)
        );
      }

      return taskItems;
    },
    async updateCpiOpportunity() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.scrollToFirstError();
        return;
      }
      this.saving = true;
      if (!this.cpiOpportunity.minLoanAmount) {
        this.cpiOpportunity.minLoanAmount = null;
      }
      if (!this.cpiOpportunity.maxLoanAmount) {
        this.cpiOpportunity.maxLoanAmount = null;
      }

      try {
        await updateCpiOpportunity(this.cpiOpportunity.id, this.cpiOpportunity);
        this.$AppEventBus.$emit('snacktime', {
          type: 'success',
          message: 'Cpi Opportunity Changes Have Been Saved',
        });
      } catch (e) {
        this.$AppEventBus.$emit('snacktime', {
          type: 'error',
          message: 'Unable to update Cpi Opportunity',
        });
      }
      this.saving = false;
    },
    formatDatePicker(date) {
      return date ? this.$moment(date).format('YYYY-MM-DD') : null;
    },
    backToList() {
      this.$router.push(`/cpi-opportunities`);
    },
    async saveOpportunityResult() {
      this.cpiOpportunity.status = 'closed';
      if (this.openOpportunityResults.some(r => r === this.opportunityResult)) {
        this.cpiOpportunity.status = 'open';
      }
      this.cpiOpportunity.result = this.opportunityResult;
      await this.updateCpiOpportunity();
      this.showResultEditDialog = false;
    },
    addTaskItemDialog(section) {
      this.customTaskItemName = null;
      this.selectedSection = section;
      this.showAddTaskItemDialog = true;
    },
    async saveCustomTaskItem() {
      this.saving = true;
      const section = this.cpiOpportunityTaskItems[this.selectedSection];
      const max = section.reduce(
        (prev, cur) => (prev < cur.step ? cur.step : prev),
        0
      );
      const newTask = await createOpportunityTask({
        title: this.customTaskItemName,
        section: this.selectedSection,
        opportunityId: this.cpiOpportunity.id,
        step: max + 1,
        status: 'Not Started',
      });
      section.push(newTask);

      this.saving = false;
      this.showAddTaskItemDialog = false;
    },
  },
};
</script>

<style lang="scss">
.cpi-opportunity {
}
</style>
